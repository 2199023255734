import "@local/css/tailwind.css";
// import "@local/sass/main.scss";
import { register as registerMenus } from "@local/ts/interactions/MenuInteractions";

import GLightbox from "glightbox";
window.GLightbox = GLightbox;

import { domReady, domSelectorReady } from "@local/ts/lib/Utility";
import * as IMask from "@local/ts/interactions/InputMaskInteractions";
import * as DeltaScroll from "@local/ts/interactions/DeltaScrollInteractions";
import * as ElementToggleInteractions from "@local/ts/interactions/ElementToggleInteractions";
window["ElementToggleInteractions"] = ElementToggleInteractions;
import * as GLightboxInteractions from "@local/ts/interactions/GLightboxInteractions";
import { svelteDOM } from "@local/ts/lib/svelte-dom-loader";
import { reactDOM } from "@local/ts/lib/react-dom-loader";

// collapsible svelte component
import Accordion from "@local/svelte/FaqAccordion.svelte";
svelteDOM.registerComponents([
  {
    component: Accordion,
    name: "Accordion",
    props: { name: "typescript" },
  },
]);

(async () => {
  await domReady();
  registerMenus();
  GLightboxInteractions.register();
  IMask.mask();
  DeltaScroll.register(50, 30, 60);
  ElementToggleInteractions.register();

  const body = await domSelectorReady("body");
  body[0].classList.remove("disable-animations");
})();

console.log("App loaded");
