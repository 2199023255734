import IMask from "imask";

const mask_routines = {
  telephone: ($element) => {
    IMask($element, { mask: "(000) 000-0000" });
  },
};

/**
 * runs input masking routines for any elements that have a data-input-mask
 * attribute that matches one of the internal behavior names
 */
const mask = (): void => {
  document
    .querySelectorAll("[data-input-mask]")
    .forEach(($element: HTMLElement) => {
      const mask_requested = $element.dataset.inputMask;

      if (typeof mask_routines[mask_requested] === "function") {
        mask_routines[mask_requested]($element);
      }
      delete $element.dataset.mask;
    });
};

export { mask };
