import { throttle } from "../lib/Utility";

const $body = document.querySelector("body");
let last_offset = 0;

/**
 * applies two data attributes to the body to make targeting via css possible:
 * data-mouse-delta and data-below-fold. the delta will either be up or down to
 * indicate scroll direction (useful for building dynamic navbars), while below
 * fold is true if the user is scrolled at least a page downward (useful for
 * return to top buttons).
 * @param dead_zone the number of pixels the page must be scrolled before the
 * delta is considered "down"
 * @param minimum_movement the number of pixel difference required before the
 * delta direction is decided
 * @param below_threshold the number of pixels the page must be scrolled before
 * the below-threshold attribute is set to true
 */
const register = (dead_zone = 0, minimum_movement = 0, below_threshold:number = 200): void => {
    window.addEventListener(
        "scroll",
        throttle(() => {
            // don't process if the body has data-saved-scroll-top
            if ($body.hasAttribute("data-saved-scroll-top")) {
                return;
            }

            const offset =
                document.documentElement.scrollTop;

            // decide mouse delta
            if (offset > last_offset + minimum_movement && offset > dead_zone) {
                $body.dataset.mouseDelta = "down";
            } else if (offset < last_offset - minimum_movement || offset === 0) {
                $body.dataset.mouseDelta = "up";
            }

            // decide if we're below the fold
            const client_height = document.documentElement.clientHeight;
            $body.dataset.belowFold = offset >= client_height ? "true" : "false";

            // decide if below threshold
            $body.dataset.belowThreshold = offset >= below_threshold ? "true" : "false";

            // set our stored offset
            if (
                offset === 0 ||
                offset > last_offset + minimum_movement ||
                offset < last_offset - minimum_movement
            ) {
                last_offset = offset;
            }
        })
    );
};

export { register };
